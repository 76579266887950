import React from 'react'
import { Box } from '@mui/material'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import VoteOption from './VoteOption'

import { useVoteState, useVoteFunctions } from './provider';

export default function VoteDefault() {
    const voteState = useVoteState()
    const voteFunctions = useVoteFunctions()

    const contentStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const onDragEnd = React.useCallback((event) => {
        if (!event.destination || event.reason === 'CANCEL') { return }
        let tempOptions = [...voteState.default_vote_order]
        tempOptions.splice(event.destination.index, 0, tempOptions.splice(event.source.index, 1)[0])
        voteFunctions.updateDefaultVoteOptions(tempOptions)
    }, [voteState.options, voteFunctions.updateDefaultVoteOptions])

    return (
        <>
            <Box style={contentStyle}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="options">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {voteState.default_vote_order.map((option, index) => (
                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <VoteOption option={option} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </>
    )
}