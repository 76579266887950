import React from 'react';


import { useDataFunctions } from 'contexts/Data';



export default function (props) {
  const data = useDataFunctions()
    
  const privateCall = () => {
    console.log("private call")
    data.getPrivateServiceHealth()
      .then((res) => {
        console.log("private call response", res)
      })
      .catch((err) => {
        console.error("private call error", err)
      })
  }

    return (
      <>
        <h1>Call Test</h1>
        <button onClick={privateCall}>Private Call</button>
        </>
    )
}
