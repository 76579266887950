import React from "react";
import { Box } from '@mui/material';

import { useChatState } from "./provider";

export default function Message({ message, ...rest }) {
    const isUserMessage = message.sourceType === "user";
    const justify_message = isUserMessage ? "right" : "left";
    const chatState = useChatState();

    const styles = {

        backgroundColor: isUserMessage ? chatState.userMessageBackground : chatState.botMessageBackground,
        textColor: isUserMessage ? 'black' : 'white',
        color: chatState.textColor,
        padding: '10px',
        margin: '10px',
        display: 'inline-block',
        borderRadius: chatState.borderRadius,
        BorderTopLeftRadius:  isUserMessage ? chatState.borderRadius : 0,
        borderTopRightRadius: isUserMessage ? 0 : chatState.borderRadius,
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: justify_message }}>
                <Box styles={styles}>
                    <div style={styles}>
                        {message.text}
                    </div>
                </Box>
            </div>
        </>
    );
}