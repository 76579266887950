import React from 'react';



export default function (props) {
    

    return (
      <>
        <h1>Chat</h1>
        </>
    )
}
