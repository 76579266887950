import React from 'react'

import ReactJson from 'react-json-view'

const sampleJson = {
    "array": [1, 2, 3],
    "boolean": true,
    "null": null,
    "number": 123,
    "object": {"a": "b", "c": "d"},
    "string": "Hello World"
}


export default function JSONView({ target_object, ...rest }) {

    const [json, setJson] = React.useState(target_object || sampleJson)

    

    return (
        <>
            <ReactJson 
                src={json}
                // theme="monokai"
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                indentWidth={4}
                iconStyle="triangle"
                name={false}
            />
        </>
    )

}
