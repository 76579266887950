import React from 'react'

import ProfileMenu from 'components/ProfileMenu';

import AdminGraph from 'components/Admin/AdminGraph';




export default function Admin() {



    React.useEffect(() => {
        console.log("loaded main.js")
    }, [])

    return (
        <>
            <div>
                <ProfileMenu />
            </div>
            
            <div>
                <h1>Admin</h1>
                <AdminGraph />

            </div>

        </>
    )

}

