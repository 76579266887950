import React from 'react'
import { GraphProvider } from './provider'
import GraphContainer from './container'
import GraphController from './controller'


export default function Graph({ graph_type, ...rest}) {

    var config = {}
    if (graph_type !== undefined) {
        // console.log("graph_type", graph_type)
        config.graph_type = graph_type
    }

    React.useEffect(() => {
        // console.log("loaded graph.js")
    }, [])

    return (
        <>
            <GraphProvider config={config} >
                <GraphContainer>
                    <GraphController />
                </GraphContainer>
            </GraphProvider>
        </>
    )

}
