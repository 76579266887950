import React from 'react';

import BaseFavicon from './sourceImages/favicon.png';
import PrologisIcon from './sourceImages/prologisfavicon.png';

// import my config.json file
import config from 'config.json';

const favicon_map = {
    "supportbot": PrologisIcon,
    "default": BaseFavicon
}

export default function Logo (params) {
    const [currentFavicon, ] = React.useState(
        favicon_map[config.frontend_type] || favicon_map["default"]);

    // set the favicon in the tab to the current favicon
    React.useEffect(() => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = currentFavicon;
        document.getElementsByTagName('head')[0].appendChild(link);
    }, [currentFavicon]);




    
    return (
        <img src={currentFavicon} alt="SocialBoterfly" {...params} />
    )
    
}