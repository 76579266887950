import React from 'react';

import ProfileMenu from '../components/ProfileMenu';
import JSONView from '../components/JSONView';
import LayoutForm from '../components/Layout/LayoutForm';
import Note from '../components/Note';


import Layout from '../components/Layout';

export default function Offline() {

  const available_components = [
    { name: "profile", component: <ProfileMenu/> },
    { name: "JSONView", component: <JSONView/> },
    { name: "LayoutForm", component: <LayoutForm/> },
    { name: "Note", component: <Note/>},
  ]

  React.useEffect(() => {
    console.log("loaded offline")
  }, [])

  return (
    <Layout
      available_components={available_components}
    />
  )

}
