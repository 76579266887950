import React from 'react'
import Message from './Message'

import { Box, List } from '@mui/material'

import { useChatState } from './provider'




export default function MessageStream() {
    const chatState = useChatState()
    
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    background: chatState.conversationContainerBackground,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    flexDirection: 'column',
                }}
            >
                <div>
                    <List
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        {/* {chatState.messages.map((message) => ( */}
                        {/* I need to use the index as the id for now */}
                        {chatState.messages.map((message, index) => (
                            <Message key={index} message={message} />
                        ))}
                    </List>
                </div>
            </Box>
        </>
    )

}
