import React from 'react';
import { GenericReducer, GenericReducerFunctionMap } from 'contexts/GenericReducer';
import { defaultConfig } from './defaults';

import { useData, useDataFunctions } from 'contexts/Data';


// #region Contexts

const contentContext = React.createContext();
function useContext() {
    const context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error('useContext must be used within an AuthProvider');
    }
    return context;
}

const dispatchContext = React.createContext();
function useDispatchContext() {
    const context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error('AuthDispatchContext must be used within an AuthProvider');
    }
    return context;
}
// #endregion

// #region Helper Functions

function PostUserMessage(context, dispatch, message, source="default") {
    const newMessage = {
        type: "text",
        content: message,
        source: source,
        type: "user_message",
    }
    context.dataFunctions.postToWebsocket(newMessage)
}

function UpdateChatMessages(context, dispatch, messages) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ messages: messages });
}


// Export Functions

export function useChatFunctions() {
    const context = useContext();
    const dispatch = useDispatchContext();
    const functionMap = {
        logSettings: () => LogSettings(context, dispatch),
        PostUserMessage: (message) => PostUserMessage(context, dispatch, message),
        UpdateChatMessages: (messages) => UpdateChatMessages(context, dispatch, messages),
    };
    return functionMap;
}

export function useChatState() {
    const context = useContext();
    return context;
}

export function ChatProvider({ children, ...rest }) {
    const [loaded, setLoaded] = React.useState(false);
    const data = useData();
    const dataFunctions = useDataFunctions();

    const [contextState, contextDispatch] = React.useReducer(GenericReducer, {
        ...defaultConfig,
        data: data,
        dataFunctions: dataFunctions,
        });

    React.useEffect(() => {
        // console.log("loaded ChatProvider.js")
        setLoaded(true)
    }, []);

    return loaded ?
        (
            <contentContext.Provider value={contextState}>
                <dispatchContext.Provider value={contextDispatch}>
                    {children}
                </dispatchContext.Provider>
            </contentContext.Provider>
        ) : (
            <>
                {/* loading screen */}
                <div>Chat Provider Loading</div>
            </>
        );
}



