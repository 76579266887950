import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    TextField,
} from '@mui/material'
// need the circular progress for while the request is being processed
import CircularProgress from '@mui/material/CircularProgress';
import { GridStatus } from 'components/Core/Common'
import { EmailField, emailValid, AuthenticationCodeField } from 'components/Core/Fields'
import { useAuthFunctions, useAuthState } from 'contexts/Auth';

import Logo from 'media/Logo';



export default function Login(props) {
    const auth = useAuthFunctions()
    const authState = useAuthState()
    const [email, setEmail] = React.useState('')
    const [authCode, setAuthCode] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [inputError, setInputError] = React.useState('')
    const [inputSuccess, setInputSuccess] = React.useState('')

    const [testButton, setTestButton] = React.useState(false)

    const handleError = (err) => {
        console.error(err)
        setInputError("Something Went Wrong")
        setIsLoading(false)
    }

    const handleProcessing = () => {
        setIsLoading(true)
        setInputError('')
        setInputSuccess('')
    }

    const handleAuthSuccess = React.useCallback((result) => {
        console.log("handleAuthSuccess", result)
    }, [props])


    const handleContinue = React.useCallback((event) => {
        if (event) { event.preventDefault() }
        if (isLoading) { return }
        if (!authState.session) {
            // If session is empty, we have yet to get a valid callback
            if (!emailValid(email)) { return }
            handleProcessing()
            auth.initiate_auth(email)
                .then((res) => {
                    if (res.error) {
                        setInputError(res.error)
                        setIsLoading(false)
                        return
                    }
                    setInputSuccess("Code Sent")
                    setIsLoading(false)
                })
                .catch(handleError)
        }
        else {
            if (!authCode) { return }
            handleProcessing()
            console.log("started processing")
            auth.verify_auth(authCode)
                .then((res) => {
                    console.log("verify_auth result", res)
                    setInputSuccess("Code Verified")
                    setIsLoading(false)
                    handleAuthSuccess(res)
                })
                .catch(handleError)
        }
    }, [email, authCode, isLoading, setIsLoading, handleAuthSuccess])

    React.useEffect(() => {
        console.log("loaded auth")
        auth.logSettings()

    }, [])

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    }


    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        margin: '1rem',
    }

    return (
        <>
            <Box
                style={containerStyle}
                onClick={e => e.stopPropagation()}

            >
                <Paper style={formStyle}>
                    <Logo />
                    <Typography
                        variant="h4"
                        align='center'
                        color="primary"
                    >
                        Login
                    </Typography>
                    {!authState.session ? (
                        <EmailField
                            autoFocus
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            onEnter={handleContinue}
                        />
                    ) : (
                        <AuthenticationCodeField
                            autoFocus
                            value={authCode}
                            onChange={(e) => { setAuthCode(e.target.value) }}
                            onEnter={handleContinue}
                        />
                    )}
                    <GridStatus
                        success={inputSuccess}
                        error={inputError}
                    />
                    {isLoading ? ( <CircularProgress /> ) :
                    !authState.session ? (
                        <Button
                            style={{ width: 200, color: 'primary' }}
                            onClick={handleContinue}
                        >
                            Email Auth
                        </Button>) : (
                        <Button
                            style={{ width: 200, color: 'primary' }}
                            onClick={handleContinue}
                        >
                            Verify Code
                        </Button>
                    )
                    }

                </Paper>
            </Box>
        </>
    )
}
