import React from 'react';
import { useDataFunctions } from '../../contexts/Data';
import { useChatState, useChatFunctions } from './provider';

// this is responsible for recieving the websocket events and reacting to them. 
export default function ChatController(props) {
  const chatState = useChatState();
  const dataFunctions = useDataFunctions();
  const chatFunctions = useChatFunctions();



  const onChatUpdateEvent = React.useCallback((data) => {
    if (data.chat_state) {
      if (data.chat_state.messages) {
        var new_message_array = []
        data.chat_state.messages.forEach((message) => {
          const message_element = {
            id: message.metadata.id,
            text: message.content.message_content,
            contentType: "text",
            timestamp_epoch: message.metadata.timestamp,
            sourceType: message.metadata.source_type,
          }
          new_message_array.push(message_element)
        })
        chatFunctions.UpdateChatMessages(new_message_array)
      }
    }
  }, [])


  React.useEffect(() => {
    dataFunctions.subscribeToWebsocketMessage(
      onChatUpdateEvent,
      {
          "type": "chat_update"
      }
  )
  dataFunctions.postToWebsocket({
      "type": "chat_update",
      "chat_id": "default"
  })
  }, [])

  return (
    <>
      {props.children}
    </>
  );
}
