import React, { useEffect, useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import cxtmenu from 'cytoscape-cxtmenu';
import edgehandles from 'cytoscape-edgehandles';
import dagre from 'cytoscape-dagre';
// import './WorkflowEditor.css'; // Include your CSS styles

// Register extensions
cytoscape.use(cxtmenu);
cytoscape.use(edgehandles);
cytoscape.use(dagre);

const WorkflowEditor = () => {
  const containerRef = useRef(null);
  const cyRef = useRef(null);
  const [elements, setElements] = useState(getMockedElements());

  useEffect(() => {
    // Initialize Cytoscape instance
    const cy = cytoscape({
      container: containerRef.current,
      elements: elements,
      style: [
        {
          selector: 'node',
          style: {
            label: 'data(label)',
            'text-valign': 'center',
            'text-halign': 'center',
            shape: 'rectangle',
            'background-color': '#0074D9',
            color: '#fff',
          },
        },
        {
          selector: 'node[type="trigger"]',
          style: {
            'background-color': '#FF4136',
          },
        },
        {
          selector: 'edge',
          style: {
            'curve-style': 'bezier',
            'target-arrow-shape': 'triangle',
            'line-color': '#ccc',
            'target-arrow-color': '#ccc',
          },
        },
      ],
    });

    // Save Cytoscape instance
    cyRef.current = cy;

    // Initialize Cytoscape context menu
    cy.cxtmenu({
      selector: 'node',
      commands: [
        {
          content: 'Add Task',
          select: (ele) => handleAddTask(ele),
        },
        {
          content: 'Edit Task',
          select: (ele) => handleEditTask(ele),
        },
        {
          content: 'Delete Task',
          select: (ele) => handleDeleteTask(ele),
        },
      ],
    });

    // Initialize Cytoscape edgehandles
    const eh = cy.edgehandles({
      snap: true,
      noEdgeEventsInDraw: true,
      disableBrowserGestures: true,
      complete: (sourceNode, targetNode, addedEles) => {
        // Update React state with new edge
        const newEdge = {
          data: {
            id: `e${sourceNode.id()}_${targetNode.id()}`,
            source: sourceNode.id(),
            target: targetNode.id(),
          },
        };
        setElements((prevElements) => [...prevElements, newEdge]);
      },
    });

    // Layout configuration
    const layout = cy.layout({
      name: 'dagre',
      rankDir: 'LR', // Left to Right
      nodeSep: 50,
      edgeSep: 10,
      rankSep: 100,
      animate: true,
    });
    layout.run();

    // Event listeners to synchronize React state with Cytoscape
    cy.on('add remove data', 'node, edge', () => {
      setElements(cy.elements().jsons());
    });

    // Clean up on unmount
    return () => {
      cy.destroy();
    };
  }, []);

  // Placeholder functions
  const handleAddTask = (ele) => {
    console.log('Add Task', ele.id());
    // Implement your add task logic here
    const newNodeId = `task${Date.now()}`;
    const newNode = {
      data: {
        id: newNodeId,
        label: 'New Task',
        type: 'function', // Default type
        inputs: [],
        outputs: [],
      },
    };
    // Add node to Cytoscape instance and state
    cyRef.current.add(newNode);
    cyRef.current.add({
      data: {
        id: `e${ele.id()}_${newNodeId}`,
        source: ele.id(),
        target: newNodeId,
      },
    });
    cyRef.current.layout({ name: 'dagre', rankDir: 'LR' }).run();
  };

  const handleEditTask = (ele) => {
    console.log('Edit Task', ele.id());
    // Implement your edit task logic here
  };

  const handleDeleteTask = (ele) => {
    console.log('Delete Task', ele.id());
    // Remove node from Cytoscape instance and state
    cyRef.current.remove(ele);
  };

  return (
    <div style={{ height: '600px', width: '100%' }} ref={containerRef}></div>
  );
};

export default WorkflowEditor;
