import React from 'react';
import { Model, Actions } from 'flexlayout-react';
import { GenericReducer } from 'contexts/GenericReducer';
import { defaultConfig } from './defaults';
import { useData, useDataFunctions } from 'contexts/Data';

// #region Contexts

const contentContext = React.createContext();
function useContext() {
  const context = React.useContext(contentContext);
  if (context === undefined) {
    throw new Error('useContext must be used within an AuthProvider');
  }
  return context;
}

const dispatchContext = React.createContext();
function useDispatchContext() {
  const context = React.useContext(dispatchContext);
  if (context === undefined) {
    throw new Error('AuthDispatchContext must be used within an AuthProvider');
  }
  return context;
}
// #endregion

// #region Helper Functions

function addTab(context, dispatch, name, component, toNodeId, location, index, select, config = {}) {
  if (!context.model) {
    console.log('context.model is undefined');
    return;
  }
  const newNodeJson = {
    type: 'tab',
    name: name,
    component: component,
    config: config, // Pass the config object here
  };
  const addNodeAction = Actions.addNode(newNodeJson, toNodeId, location, index, select);

  // Use doAction to mutate the model; flexlayout-react will handle re-rendering
  context.model.doAction(addNodeAction);
}


function factory(context, dispatch, component, node) {
  const target = context.factory_array.find((target) => target.name === component);
  if (target) {
    const config = node.getConfig(); // Get the config from the node
    // Clone the component and pass the config as props
    return React.cloneElement(target.component, { ...config });
  } else {
    return context.factory_default;
  }
}

// Export Functions

export function useLayoutFunctions() {
  const context = useContext();
  const dispatch = useDispatchContext();
  const functions = {
    addTab: (name, component, toNodeId, location, index, select, config) =>
      addTab(context, dispatch, name, component, toNodeId, location, index, select, config),
    factory: (component, node) => factory(context, dispatch, component, node),
  };
  return functions;
}

export function useLayoutState() {
  const context = useContext();
  return context;
}

export function LayoutProvider({ children, config, available_components, ...rest }) {
  const [loaded, setLoaded] = React.useState(false);
  const data = useData();
  const dataFunctions = useDataFunctions();
  if (!available_components) {
    available_components = [];
  }

  const initialState = {
    ...defaultConfig,
    ...config,
    ...rest,
    data: data,
    dataFunctions: dataFunctions,
    factory_array: available_components,
  };

  const [contextState, contextDispatch] = React.useReducer(GenericReducer, initialState);

  // Use useRef to keep the same model instance
  const modelRef = React.useRef(null);

  if (!modelRef.current) {
    modelRef.current = Model.fromJson({
      global: contextState.global,
      borders: contextState.borders,
      layout: contextState.layout,
    });
  }

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return loaded ? (
    <contentContext.Provider value={{ ...contextState, model: modelRef.current }}>
      <dispatchContext.Provider value={contextDispatch}>{children}</dispatchContext.Provider>
    </contentContext.Provider>
  ) : (
    <>
      {/* loading screen */}
      <div>Layout Provider Loading</div>
    </>
  );
}
