import React, { Fragment } from 'react';
import {
    Grid, 
    Typography,
    TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'

export function GridStatus(props) {
    return (
        <Fragment>
            {props.error &&
                <Grid item xs={12} md='auto'>
                    <Typography color='error'>
                        <ClearIcon color='error' />
                        {props.error}
                    </Typography>
                </Grid>
            }
            {props.success &&
                <Grid item xs={12} md='auto'>
                    <Typography color='primary'>
                        <CheckIcon color='primary' />
                        {props.success}
                    </Typography>
                </Grid>
            }
        </Fragment>
    )
}

export function GridTextField(props) {
    const [selected, setSelected] = React.useState(false)
    const [value, setValue] = React.useState(props.value || props.defaultValue || '')
    const validationFunction = props.validationFunction || ((value) => { return true })
    const onValidInput = props.onValidInput || ((value) => { })
    const onChange = (e) => {
        setValue(e.target.value)
        setSelected(true)
        if (typeof props.onChange === "function") {
            props.onChange(e)
        }
        if (validationFunction(value)) {
            onValidInput(e)
        }
    }
    const onBlur = () => {
        setSelected(true)
        if (typeof props.onBlur === "function") {
            props.onBlur()
        }
    }
    const onKeyPress = (e) => {
        if (typeof props.onEnter === "function" && e.key === 'Enter') {
            props.onEnter(e)
        }
        if (typeof props.onKeyPress === "function") {
            props.onKeyPress(e)
        }
    }

    return (
        <Grid item xs={props.xs || 6}>
            <TextField
                // {...props}
                fullWidth
                value={value}
                margin={props.margin || "normal"}
                InputLabelProps={props.InputLabelProps || {
                    shrink: true,
                }}
                error={selected && !validationFunction(value)}
                onChange={onChange}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
            />
        </Grid>
    )
}



