import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';

import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Radio,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useGraphFunctions, useGraphState } from './provider';
import { Box } from '@mui/system';

export default function GraphSettings(props) {

    const graphState = useGraphState()
    const graphFunctions = useGraphFunctions()


    const toggleNodeTypeDisplayed = React.useCallback((node_type) => {
        console.log(node_type)
        graphFunctions.toggleNodeTypeDisplayed(node_type)
    }, [graphFunctions, graphState.excludedNodeTypes])

    const toggleEdgeTypeDisplayed = React.useCallback((edge_type) => {
        console.log(edge_type)
        graphFunctions.toggleEdgeTypeDisplayed(edge_type)
    }, [graphFunctions, graphState.excludedEdgeTypes])  

    const toggleEdgeTypeAsParent = React.useCallback((edge_type) => {
        console.log(edge_type)
        graphFunctions.toggleEdgeTypeAsParent(edge_type)
    }, [graphFunctions, graphState.edge_types_to_be_parents])

    const containerDivStyle = {
        // I need it to be able to scroll internally, the parent has overflow hidden
        overflow: 'auto',
        height: '100%',
        width: '100%',
    }




    return (
        <>
            <Box sx={containerDivStyle}>
            <h1>Graph Settings</h1>
            <Accordion>
                <AccordionSummary>
                    <Typography>Node Type Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {graphState.nodeTypes.map((node_type, index) => (
                            <div key={index}>
                                <ListItem>
                                    <ListItemText primary={node_type} />
                                    <ListItemIcon>
                                        <Radio
                                            checked={graphState.excludedNodeTypes.includes(node_type) ? false : true}
                                            onClick={() => toggleNodeTypeDisplayed(node_type)}
                                            value={node_type}
                                            name={node_type}
                                        />
                                    </ListItemIcon>
                                </ListItem>
                            </div>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary>
                    <Typography>Edge Type Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* Lets make a header row so that this can be a table of the edge types */}
                    {/* I will want to have if the edge type is displayed, then if it is a parent type */}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Edge Type</TableCell>
                                    <TableCell>Display</TableCell>
                                    <TableCell>Parent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {graphState.edgeTypes.map((edge_type, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{edge_type}</TableCell>
                                        <TableCell>
                                            <Radio
                                                checked={graphState.excludedEdgeTypes.includes(edge_type) ? false : true}
                                                onClick={() => toggleEdgeTypeDisplayed(edge_type)}
                                                value={edge_type}
                                                name={edge_type}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {/* this needs to be grayed out if the type is not displayed */}
                                            <Radio
                                                checked={graphState.edge_types_to_be_parents.includes(edge_type) ? true : false}
                                                onClick={() => toggleEdgeTypeAsParent(edge_type)}
                                                value={edge_type}
                                                name={edge_type}
                                                disabled={graphState.excludedEdgeTypes.includes(edge_type) ? true : false}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                </AccordionDetails>                
            </Accordion>
            </Box>
            </>
            )
}
