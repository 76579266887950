import React from 'react';
import { useGraphState, useGraphFunctions } from './provider';
import { useDataFunctions } from '../../contexts/Data';
import Template from './display';
import GraphSettings from './settings';

import {  
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// this is responsible for recieving the websocket events and reacting to them. 
export default function GraphController(props) {
  const graphState = useGraphState();
  const graphFunctions = useGraphFunctions();
  const dataFunctions = useDataFunctions();

  const applyBackgroundImages = React.useCallback((node) => {
    // console.log("node information", node)
    if (graphState.backgroundImageMap[node.type]) {
      node.backgroundImage = graphState.backgroundImageMap[node.type];
    }
    return node;
  }, [graphState])

  const onGraphUpdate = React.useCallback((data) => {
    // console.log("onGraphUpdate", data)
    if (["create", "update"].includes(data.data.operation_type)) {
      var bGraphUpdate = {
        nodes: [],
        edges: []
      }
      if (data.data.resource_type === "node") {
        // data.data.image.backgroundImage = "https://i.imgur.com/fHyEMsl.jpeg"
        applyBackgroundImages(data.data.image)
        bGraphUpdate.nodes.push(data.data.image)
      }
      if (data.data.resource_type === "edge") {
        bGraphUpdate.edges.push(data.data.image)
      }
      // console.log("bGraphUpdate", bGraphUpdate)
      graphFunctions.AddOrUpdateBGraphResources(bGraphUpdate)
    }
    if (data.data.operation_type === "delete") {
      graphFunctions.RemoveBGraphResources([data.data.image.id])
    }
  }, [graphFunctions])

  const onGraphInit = React.useCallback((data) => {
    data.data.nodes.forEach(node => {
      applyBackgroundImages(node)
    });
    graphFunctions.AddOrUpdateBGraphResources(data.data)
  }, [graphFunctions])

  React.useEffect(() => {
    dataFunctions.subscribeToWebsocketMessage(
      onGraphInit,
      {
        "type": "graph_init",
        "index": graphState.graph_type
      }
    )
    dataFunctions.subscribeToWebsocketMessage(
      onGraphUpdate,
      {
        "type": "graph_update",
        "index": graphState.graph_type
      }
    )
    // console.log("grpah state in controller", graphState)
    dataFunctions.postToWebsocket({
      "type": "graph_subscribe",
      "graph_type": graphState.graph_type,
    })
  }, [])

  return (
    <>
      <div style={{ width: graphState.settingsOpen ? '0' : '100%', height: graphState.settingsOpen ? '0' : '100%' }}>
        <Template />
      </div>
      <div style={{ width: !graphState.settingsOpen ? '0' : '100%', height: !graphState.settingsOpen ? '0' : '100%' }}>
        <GraphSettings /> 
      </div>
      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={{ position: 'absolute', top: 16, right: 16 }}
        icon={<SettingsIcon />}
        onClick={() => { graphFunctions.toggleSettings() }}
        direction='down'
      // size='small'
      >

        <SpeedDialAction
          key="Add Node"
          icon={<AddCircleIcon />}
          tooltipTitle="Add Node"
          onClick={() => { console.log("Add Node not implemented") }}
        />
        <SpeedDialAction
          key="Edge Drawing Mode"
          icon={<InsertLinkIcon />}
          tooltipTitle="Edge Drawing Mode"
          onClick={() => { console.log("Edge Drawing Mode not implemented") }}
        />
      </SpeedDial>

    </>
  );
}
