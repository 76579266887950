import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#800080',
      dark: '#811181',
      light: '#B7E59A',
    },
    secondary: {
      main: '#A3A3A3', // Light gray, form elements
    },
    error: {
      main: '#FE1818', // "Alerts and errors"
    },
    // warning: {},
    info: {
      main: '#2CB5E5', // "Links and highlights" (blue)
    },
    success: {
      main: '#71BE45', // "Light green highlight"
    },
    background: {
      paper: '#FFFFFF',
      default: '#EFEFEF',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ],
  },
});

export default defaultTheme;
