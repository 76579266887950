import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';


import { useNoteFunctions, useNoteState } from './provider';

import MDEditor from '@uiw/react-md-editor';
import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";

const code = `
**Hello world!!!**

\`\`\`js
function demo() {}
\`\`\`

\`\`\`python
def demo():
    pass
\`\`\`

# Text functions 

link rendering in markdown: [NASA](https://www.nasa.gov) 
`

export default function NoteDisplay(props) {

    const noteState = useNoteState()
    const noteFunctions = useNoteFunctions()

    const [noteHeight, setNoteHeight] = React.useState(500)

    useEffect(() => {
        console.log("Loaded Note")

    }, []);

    const [value, setValue] = React.useState(code);

    const containerStyle = {
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid black',
        borderRadius: '5px',
        overflow: 'hidden',
    }

    const lightStyleForMDEditor = {
        // backgroundColor: 'white',
        // color: 'black',

          
    }

    const container_ref = useRef(null)
    // useEffect(() => {
    //     if (container_ref.current) {
    //         console.log(container_ref.current.clientHeight)
    //         setNoteHeight(container_ref.current.clientHeight)
    //     }
    // }, [container_ref.current, setNoteHeight])

    return (
        <>
            <div 
                ref={container_ref}
                style={containerStyle}>
                <MDEditor
                    style={lightStyleForMDEditor} 
                    value={value}
                    onChange={setValue}
                    hideToolbar={true}
                    preview='preview'
                    theme="light"
                    height={noteHeight}
                />
            </div>
        </>
    )
}


