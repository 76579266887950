import React from 'react';
import { useGraphState } from './provider';

export default function GraphContainer(props) {
  const graphState = useGraphState();

  const containerStyle = {
    borderRadius: graphState.borderRadius,
    background: graphState.conversationContainerBackground,
    // height: graphState.containerHeight,
    // minHeight: graphState.conversationContainerMinHeight,
    // width: graphState.containerWidth,
    // instead just take up the full width and height of the parent
    width: '100%',
    height: '100%',
    borderColor: graphState.borderColor,
    borderStyle: graphState.borderStyle,
    display: 'flex',
    flexDirection: 'column',
    overflow: "hidden"
  };

  return (
    <>
      {graphState.isOpen && (
        <div style={containerStyle}>
          {props.children}
        </div>
      )}
    </>
  );
}
