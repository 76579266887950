import React from 'react';
import { useChatState } from './provider';

export default function ChatContainer(props) {
  const chatState = useChatState();

  const containerStyle = {
    borderRadius: chatState.borderRadius,
    background: chatState.conversationContainerBackground,
    height: chatState.conversationContainerHeight,
    // height: '100%',
    // width: '100%',
    minHeight: chatState.conversationContainerMinHeight,
    width: chatState.conversationContainerWidth,
    borderColor: chatState.borderColor,
    borderStyle: chatState.borderStyle,
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <>
      {chatState.isOpen && (
        <div style={containerStyle}>
          {props.children}
        </div>
      )}
    </>
  );
}
