import React from 'react'

import ProfileMenu from 'components/ProfileMenu';

import Chat from 'components/Chat';
import Graph from 'components/Graph';





export default function Main() {


    React.useEffect(() => {
        console.log("loaded main.js")
    }, [])

    return (
        <>
            <div>
                <ProfileMenu />
            </div>
            
            <div>
                <h1>Main</h1>
                
                <Chat />
                <Graph />

            </div>

        </>
    )

}
