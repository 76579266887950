


const icon_map = {
  "home": "https://fonts.gstatic.com/s/i/materialicons/home/v14/24px.svg",
  "notes": "https://fonts.gstatic.com/s/i/materialicons/notes/v14/24px.svg",
  "person": "https://fonts.gstatic.com/s/i/materialicons/person/v14/24px.svg",
  "list": "https://fonts.gstatic.com/s/i/materialicons/list/v14/24px.svg",
  "chat": "https://fonts.gstatic.com/s/i/materialicons/chat/v14/24px.svg",
  "add": "https://fonts.gstatic.com/s/i/materialicons/add/v14/24px.svg",
  "star": "https://fonts.gstatic.com/s/i/materialicons/star/v14/24px.svg",
}

export const defaultConfig = {

  isOpen: true,
  settingsOpen: false,

  graph_type: "admin",

  // // styling
  // borderColor: 'red',
  // borderRadius: '10px',
  // borderStyle: 'solid',

  containerHeight: '700px',
  containerWidth: '700px',

  textColor: 'black',

  startingCytoGraph: [],

  currentBGraph: {
    nodes: [],
    edges: []
  },



  nodeTypes: [],
  edgeTypes: [],
  excludedNodeTypes: [
    // "chat:chat_message",
    "user:cognito_user",
    "journal:entry",
    "note:note",
    "user:role",
    // "user:user",
    "journal:journal",
    "expectations:expectations",
    "user:user_preferences",
    "cache:service",
    "cache:call",
    "cache:function",
    "cache:function_call",
    "websocket:connection",
  ],
  excludedEdgeTypes: [
  ],
  edge_types_to_be_parents: [
    "chat:message_association",
    // "notegroup:note",
    // "note:sub_note",
    // "journal:has_entry",
  ],
  backgroundImageMap: {
    "chat:chat": icon_map["chat"],
    "note:note": icon_map["list"],
    "user:user": icon_map["person"],
  },



  cytoLayout: {
    name: "cola",
    directed: true,
    animate: true,
    avoidOverlap: false,
    nodeDimensionsIncludeLabels: true,
    fit: false,

    nodeSpacing: 10,
    edgeLengthVal: 45,
  },
  cytoStyle: [
    {
      selector: 'node',
      css: {
        'label': 'data(label)',
        'border-color': 'black',
        'background-fit': 'cover',
        "background-image": function (node) {
          // return node.data().backgroundImage ? 
          //   node.data().backgroundImage : 
          //   'https://i.imgur.com/xCvzudW.png';
          if (node.data().backgroundImage) {
            return node.data().backgroundImage;
          }
          return 'https://i.imgur.com/xCvzudW.png';
        },
        'text-valign': 'bottom',
        'text-halign': 'center',
        'height': '60px',
        'width': '60px',
        'border-opacity': '1',
        "text-background-opacity": 1,
        "text-background-color": "lightgray"
      }
    },
    {
      selector: 'edge',
      style: {
        'label': 'data(label)' // maps to data.label
      }
    },
    {
      selector: ':selected',
      css: {
        'background-color': 'black',
        'line-color': 'black',
        'target-arrow-color': 'black',
        'source-arrow-color': 'black'
      }
    }
  ]
};
