import React from 'react';

import { DockLocation } from 'flexlayout-react';

import { useLayoutFunctions, useLayoutState } from './provider';
import { Box, Button } from '@mui/material';

// This is going to be a form to test adding new elements to the layout
export default function LayoutForm(props) {
  const layoutState = useLayoutState();
  const layoutFunctions = useLayoutFunctions();

  const AddNewTabTest = React.useCallback(() => {
    console.log('Adding new tab');
    layoutFunctions.addTab(
      'New Tab',
      'JSONView',
      '#1b3445f5-02b9-47c6-bf59-8617ffd03137', // Tabset ID where you want to add the tab
      DockLocation.CENTER,
      0
    );
  }, [layoutFunctions]);

  const AddNewTabTestWithConfig = React.useCallback(() => {
    console.log('Adding new tab with config');
    layoutFunctions.addTab(
      'New Tab with Config',
      'JSONView',
      '#1b3445f5-02b9-47c6-bf59-8617ffd03137', // Tabset ID where you want to add the tab
      DockLocation.CENTER,
      0,
      true,
      { target_object: { key: 'value' } }
    );
  }, [layoutFunctions]);

  const TestFunction = React.useCallback(() => {
    console.log('layout form test functionality');
    console.log('layoutState', layoutState);
    console.log('layoutFunctions', layoutFunctions);
  }, [layoutFunctions, layoutState]);

  return (
    <>
      <Box>
        <h1>Layout Form</h1>
        <Button variant="contained" onClick={AddNewTabTest}>
          Add JSONView Tab
        </Button>
        <Button variant="contained" onClick={AddNewTabTestWithConfig}>
          Add JSONView Tab with config
        </Button>
        <Button variant="contained" onClick={TestFunction}>
          Log state and functions
        </Button>
      </Box>
    </>
  );
}
