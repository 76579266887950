import React from 'react'
import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuthState } from 'contexts/Auth';
import { useVoteFunctions } from './provider';


export default function VoteOption({ option, ...rest }) {
    const authState = useAuthState()
    const voteFunctions = useVoteFunctions()

    // I need styling for the container box so everything for the option stays horizontally aligned
    const containerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    }


    return (
        <>
            <Box style={containerStyle}>
                <h4>
                    {option.title}
                </h4>
                {authState.authenticated &&
                    <IconButton 
                        onClick={() => voteFunctions.deleteOption(option)}
                    >
                        <DeleteIcon />
                    </IconButton>
                }
            </Box>
        </>
    )
}