import React from 'react'
import { VoteProvider } from './provider'
import VoteContainer from './container'
import VoteRouter from './VoteRouter'


export default function Vote({activeVote, ...props}) {

    return (
        <>
            <VoteProvider activeVote={activeVote}>
                <VoteContainer>
                    <VoteRouter />
                </VoteContainer>
            </VoteProvider>
        </>
    )

}
