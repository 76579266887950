import React from 'react';
import { Layout } from 'flexlayout-react';
import 'flexlayout-react/style/light.css';

import { useLayoutFunctions, useLayoutState } from './provider';

export default function Display(props) {
  const layoutState = useLayoutState();
  const layoutFunctions = useLayoutFunctions();

  const { model } = layoutState;

  const factory = React.useCallback(
    (node) => {
      const component = node.getComponent();
      return layoutFunctions.factory(component, node);
    },
    [layoutFunctions]
  );

  return (
    <>
      <Layout model={model} factory={factory} />
    </>
  );
}
