import * as React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import defaultTheme from './themes/defaultTheme';
import darkTheme from './themes/darkTheme';
import './App.css';
import Routes from './components/Routes'
import { AuthProvider } from 'contexts/Auth';
import { DataProvider } from 'contexts/Data';

export default function App() {
  const useStyledEngine = true;
  return (
    <>
        <AuthProvider>
          <DataProvider>
            {
              useStyledEngine ? (
                <StyledEngineProvider injectFirst>
                  <StyledApp />
                </StyledEngineProvider>
              ) : (
                <StyledApp />
              )
            }
          </DataProvider>
        </AuthProvider>
      </>
      );
}

      function StyledApp() {
  const currentTheme = localStorage.getItem('theme') === 'dark' ? darkTheme : defaultTheme;
      return (
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <div className="App">
          <Routes />
        </div>
      </ThemeProvider>
      );
}
