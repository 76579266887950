import React from 'react';
import { GenericReducer, GenericReducerFunctionMap } from 'contexts/GenericReducer';
import { defaultConfig } from './defaults';

import { useData, useDataFunctions } from 'contexts/Data';


// #region Contexts

const contentContext = React.createContext();
function useContext() {
    const context = React.useContext(contentContext);
    if (context === undefined) {
        throw new Error('useContext must be used within an AuthProvider');
    }
    return context;
}

const dispatchContext = React.createContext();
function useDispatchContext() {
    const context = React.useContext(dispatchContext);
    if (context === undefined) {
        throw new Error('AuthDispatchContext must be used within an AuthProvider');
    }
    return context;
}
// #endregion

// #region Helper Functions

function PostUpdate(context, dispatch, message, source = "default") {
    const newMessage = {
        type: "text",
        content: message,
        source: source,
        type: "graph_update",
    }
    context.dataFunctions.postToWebsocket(newMessage)
}

function AddOrUpdateBGraphResources(context, dispatch, update_graph) {
    let newBGraph = context.currentBGraph;
    let newNodeTypes = context.nodeTypes;
    let newEdgeTypes = context.edgeTypes;
    update_graph.nodes.forEach((node) => {
        let nodeIndex = newBGraph.nodes.findIndex((n) => n.id === node.id);
        if (nodeIndex === -1) {
            newBGraph.nodes.push(node);
        } else {
            newBGraph.nodes[nodeIndex] = node;
        }
        // console.log("node data", node)
        if (!newNodeTypes.includes(node.type)) {
            newNodeTypes.push(node.type);
        }
    });
    update_graph.edges.forEach((edge) => {
        let edgeIndex = newBGraph.edges.findIndex((e) => e.id === edge.id);
        if (edgeIndex === -1) {
            newBGraph.edges.push(edge);
        } else {
            newBGraph.edges[edgeIndex] = edge;
        }
        if (!newEdgeTypes.includes(edge.type)) {
            newEdgeTypes.push(edge.type);
        }
    });
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ 
        currentBGraph: newBGraph,
        nodeTypes: newNodeTypes,
        edgeTypes: newEdgeTypes
    });
}

function RemoveBGraphResources(context, dispatch, target_ids) {
    let newBGraph = context.currentBGraph;
    target_ids.forEach((target_id) => {
        newBGraph.nodes = newBGraph.nodes.filter((n) => n.id !== target_id);
        newBGraph.edges = newBGraph.edges.filter((e) => e.id !== target_id);
    });
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ currentBGraph: newBGraph });
}

function DeleteElementRequest(context, dispatch, target_id) {
    const body = {
        type: "delete_element",
        element_id: target_id
    }
    context.dataFunctions.postToWebsocket(body)
}

function openSettings(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ settingsOpen: true });
}

function closeSettings(context, dispatch) {
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ settingsOpen: false });
}

function toggleSettings(context, dispatch) {
    const newSettingsOpen = !context.settingsOpen;
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ settingsOpen: newSettingsOpen });
}

function toggleNodeTypeDisplayed(context, dispatch, node_type) {
    console.log(node_type)
    var current_excluded_node_types = context.excludedNodeTypes;
    if (current_excluded_node_types.includes(node_type)) {
        current_excluded_node_types = current_excluded_node_types.filter((type) => type !== node_type)
    } else {
        current_excluded_node_types.push(node_type)
    }
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ excludedNodeTypes: current_excluded_node_types });
}

function toggleEdgeTypeDisplayed(context, dispatch, edge_type) {
    var current_excluded_edge_types = context.excludedEdgeTypes;
    if (current_excluded_edge_types.includes(edge_type)) {
        current_excluded_edge_types = current_excluded_edge_types.filter((type) => type !== edge_type)
    } else {
        current_excluded_edge_types.push(edge_type)
    }
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ excludedEdgeTypes: current_excluded_edge_types });
}

function toggleEdgeTypeAsParent(context, dispatch, edge_type) {
    var current_edge_types_to_be_parents = context.edge_types_to_be_parents;
    if (current_edge_types_to_be_parents.includes(edge_type)) {
        current_edge_types_to_be_parents = current_edge_types_to_be_parents.filter((type) => type !== edge_type)
    } else {
        current_edge_types_to_be_parents.push(edge_type)
    }
    GenericReducerFunctionMap(dispatch).UpdateContextStateMap({ edge_types_to_be_parents: current_edge_types_to_be_parents });
}


// Export Functions

export function useGraphFunctions() {
    const context = useContext();
    const dispatch = useDispatchContext();
    const functionMap = {
        logSettings: () => LogSettings(context, dispatch),
        PostUpdate: (message) => PostUpdate(context, dispatch, message),

        AddOrUpdateBGraphResources: (update_graph) => AddOrUpdateBGraphResources(context, dispatch, update_graph),
        RemoveBGraphResources: (target_ids) => RemoveBGraphResources(context, dispatch, target_ids),
        DeleteElementRequest: (target_id) => DeleteElementRequest(context, dispatch, target_id),
        openSettings: () => openSettings(context, dispatch),
        closeSettings: () => closeSettings(context, dispatch),
        toggleSettings: () => toggleSettings(context, dispatch),
        toggleNodeTypeDisplayed: (node_type) => toggleNodeTypeDisplayed(context, dispatch, node_type),
        toggleEdgeTypeDisplayed: (edge_type) => toggleEdgeTypeDisplayed(context, dispatch, edge_type),
        toggleEdgeTypeAsParent: (edge_type) => toggleEdgeTypeAsParent(context, dispatch, edge_type),
    };
    return functionMap;
}

export function useGraphState() {
    const context = useContext();
    return context;
}

export function GraphProvider({ children, config, ...rest }) {
    const [loaded, setLoaded] = React.useState(false);
    const data = useData();
    const dataFunctions = useDataFunctions();

    const [contextState, contextDispatch] = React.useReducer(GenericReducer, {
        ...defaultConfig,
        ...config,
        data: data,
        dataFunctions: dataFunctions,
    });

    React.useEffect(() => {
        // console.log("loaded GraphProvider.js")
        setLoaded(true)
    }, []);

    return loaded ?
        (
            <contentContext.Provider value={contextState}>
                <dispatchContext.Provider value={contextDispatch}>
                    {children}
                </dispatchContext.Provider>
            </contentContext.Provider>
        ) : (
            <>
                {/* loading screen */}
                <div>Graph Provider Loading</div>
            </>
        );
}



