import React from 'react';

import ProfileMenu from '../components/ProfileMenu';
import Chat from '../components/Chat';
import Graph from '../components/Graph';
import JSONView from '../components/JSONView';
import LayoutForm from '../components/Layout/LayoutForm';
import Note from '../components/Note';
import DumbNote from '../components/DumbNote';
// import WorkflowEditor from '../components/Workflow';

import Layout from '../components/Layout';

const layout = {
  "type": "column",
  "id": "#a73312d0-4df2-488e-9784-891791ed6865",
  "children": [
    {
      "type": "row",
      "id": "#e166c89b-0907-4a45-9b4e-ce3a114973a7",
      "weight": 20,
      "children": [
        {
          "type": "row",
          "id": "#fcf76896-3761-4d6b-bf0b-bbd5b1196541",
          "weight": 42.91300097751711,
          "children": [
            {
              "type": "tabset",
              "id": "#1b3445f5-02b9-47c6-bf59-8617ffd03137",
              "weight": 40,
              "children": [
                {
                  "type": "tab",
                  "id": "#d58f80f8-c95f-43ab-bbff-8d56ed75695c",
                  "name": "Workflow",
                  "component": "Workflow",
                  "enableRenderOnDemand": false,
                },
                {
                  "type": "tab",
                  "id": "#f48f80f8-c95f-43ab-bbff-8d56ed75695c",
                  "name": "Admin Graph",
                  "component": "Admin Graph",
                  "enableRenderOnDemand": false,
                },
                {
                  "type": "tab",
                  "id": "#d78a5a81-78dc-48c9-bfc0-75799b8f6c2b",
                  "name": "Personal Notes",
                  "component": "DumbNote",
                  "enableRenderOnDemand": false
                },
                {
                  "type": "tab",
                  "id": "#f48f80f8-jour-43ab-bbff-8d56ed75695c",
                  "name": "Journal",
                  "component": "Journal",
                  "enableRenderOnDemand": false
                },
                {
                  "type": "tab",
                  "id": "#f48f80f8-expect-43ab-bbff-8d56ed75695c",
                  "name": "Daily Expectations",
                  "component": "Expectations",
                  "enableRenderOnDemand": false
                },

                {
                  "type": "tab",
                  "id": "#4594ae25-c743-4a9d-854c-c6884e775faa",
                  "name": "Layout Form",
                  "component": "LayoutForm"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default function WorkflowManager() {
  const available_components = [
    { name: "Chat", component: <Chat /> },
    { name: "Admin Graph", component: <Graph /> },
    { name: "profile", component: <ProfileMenu /> },
    { name: "JSONView", component: <JSONView /> },
    { name: "LayoutForm", component: <LayoutForm /> },
    { name: "Note", component: <Note /> },
    { name: "DumbNote", component: <DumbNote /> },
    { name: "Journal", component: <DumbNote noteType="journal_entry" /> },
    { name: "Expectations", component: <DumbNote noteType="expectations_note" /> },
    // { name: "Workflow", component: <WorkflowEditor /> }
  ];

  return (
    <div>
      <Layout available_components={available_components} layout={layout} />
    </div>
  );
}
