export const defaultConfig = {

  isOpen: true,
  settingsOpen: false,


  containerHeight: '700px',
  containerWidth: '700px',

  textColor: 'black',

};
