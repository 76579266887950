import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.React = React;

const container = document.getElementById('socialboterfly');
const root = createRoot(container); 
root.render(<App />);

serviceWorker.unregister();
