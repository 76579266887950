import React from 'react';
import MDEditor, { commands } from '@uiw/react-md-editor';
import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import { useData, useDataFunctions } from 'contexts/Data';
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';

export default function DumbNote(props) {

  // If there is a note type passed in, use that, otherwise use the default
  const noteType = props.noteType || 'personal_notes';

  const data = useData();
  const dataFunctions = useDataFunctions();

  const [value, setValue] = React.useState('Loading...');
  const [serverValue, setServerValue] = React.useState('');
  const [isServerChanged, setIsServerChanged] = React.useState(false);

  const valueRef = React.useRef(value);
  const serverValueRef = React.useRef(serverValue);

  React.useEffect(() => {
    valueRef.current = value;
  }, [value]);

  React.useEffect(() => {
    serverValueRef.current = serverValue;
  }, [serverValue]);

  const containerStyle = {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  // Save command
  const saveCommand = {
    name: 'save',
    keyCommand: 'save',
    buttonProps: { 'aria-label': 'Save' },
    icon: <SaveIcon />,
    execute: () => {
      // dataFunctions.saveUserDefaultNoteGroup(value)
      //   .then(() => {
      //     setServerValue(value);
      //     setIsServerChanged(false);
      //   })
      //   .catch((error) => {
      //     console.log("Error saving note:", error);
      //   });
      dataFunctions.postToWebsocket({
        "type": "note_group_update",
        "note_group_type": noteType,
        "note_group_state": value
      });
    },
  };

  // Revert command
  const revertCommand = {
    name: 'revert',
    keyCommand: 'revert',
    buttonProps: { 'aria-label': 'Revert' },
    icon: <RestoreIcon />,
    execute: () => {
      setValue(serverValue);
      setIsServerChanged(false);
    },
  };

  const onWebsocketEvent = React.useCallback((event) => {
    if (event.action === 'subscribe') {
      setValue(event.note_group_state);
      setServerValue(event.note_group_state);
    } else if (event.action === 'update') {
      if (valueRef.current === serverValueRef.current) {
        setValue(event.note_group_state);
        setServerValue(event.note_group_state);
      } else {
        setServerValue(event.note_group_state);
        if (valueRef.current !== event.note_group_state) {
          setIsServerChanged(true);
        } else {
          setIsServerChanged(false);
        }
      }
    }
  }, [dataFunctions]);

  React.useEffect(() => {
    console.log("Loaded DumbNote");

    // Subscribe to websocket events
    dataFunctions.subscribeToWebsocketMessage(
      onWebsocketEvent, 
      { 
        type: 'note_group_update',
        note_group_type: noteType
      }
    );
    dataFunctions.postToWebsocket({
      "type": "note_group_subscribe",
      "note_group_type": noteType
    });

  }, []);

  const serverChangedIndicator = isServerChanged ? (
    <span style={{ color: 'red', fontWeight: 'bold' }}>Server version has changed!</span>
  ) : null;

  return (
    <>
      <div style={containerStyle}>
        {serverChangedIndicator}
        <MDEditor
          value={value}
          onChange={setValue}
          height="100%"
          preview='preview'
          extraCommands={[
            // Include default extra commands
            commands.divider,
            commands.codeEdit,    // Toggle Edit Mode
            commands.codeLive,    // Toggle Live Preview Mode
            commands.codePreview, // Toggle Preview Mode
            commands.fullscreen,  // Fullscreen Mode
            // Add custom commands
            commands.divider,
            saveCommand,
            revertCommand,
          ]}
        />
      </div>
    </>
  );
}
